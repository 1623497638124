<template lang="pug">
button.button(:class="{ active }" @click="$emit('click')")
    slot
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped lang="scss">
.button {
    position: relative;
    font-family: 'OpenSans';
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: color('gray-700');
    outline: none;

    &::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: transparent;
        transition: background-color .15s linear;
    }

    &.active::before {
        background-color: color('gray-500');
    }
}
</style>

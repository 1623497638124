<template lang="pug">
ContainerForData(width="100%" :is-loading="isLoading")
    template(#header-right)
        .flex.items-center
            Search(v-model="searchValue" @change="searchItems")
    template(#data)
        .table-content
            Table.mb-12(v-if="totalCount" v-bind="tableOptions")
                template(#image="{ value }")
                    .table-image(:style="{ backgroundImage: `url(${value})` }")
                template(#name="{ item, value }")
                    a(
                        v-if="item.skuUrl"
                        :href="item.skuUrl"
                        target="_blank"
                    ) {{ value }}
                    .name(v-else) {{ value }}
                template(#externalId="{ value }")
                    .code {{ value }}
                template(#availability="{ value }")
                    .flex.items-center.justify-center.icon
                        DoneIcon.done(v-if="value")
                        CloseIcon.close(v-else)
                template(#shop="{value}")
                    Tooltip
                      template(#reference)
                        .shop {{ value }}
                      .shop-text {{ value }}

            span(v-else) No data.

            Pagination(
                :total="totalCount"
                :current="currentPage"
                :limit="limit"
                @change-page="currentPage = $event"
            ) 
</template>

<script>
import { mapMutations } from 'vuex'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Search from '@/components/Nestle/Search.vue';
import Table from '@/components/Table/Table.vue';
import DoneIcon from '@/assets/svg/done.svg';
import CloseIcon from '@/assets/svg/close.svg';
import Pagination from "@/components/Pagination/Pagination.vue"
import Tooltip from "@/components/Elements/Tooltip.vue";

export default {
    components: {
      Tooltip,
        ContainerForData,
        Search,
        Table,
        DoneIcon,
        CloseIcon,
        Pagination
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            items: [],
            searchValue: '',

            limit: 20,
            totalCount: 0,
            currentPage: 1,

            sortBy: "skuName",
            sortOrder: "ASC",
        }
    },

    computed: {
        tableRequestParams() {
            return {
                ...this.requestParams,
                limit: this.limit,
                offset: this.limit * (this.currentPage - 1),
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                searchQuery: this.searchValue,
            }
        },

        tableColumns() {
            return [
                {
                    title: 'Изобр.',
                    slot: 'image',
                    width: 40,
                    value: (item) => item.imageUrl,
                },
                {
                    title: 'Название SKU',
                    width: 250,
                    slot: 'name',
                    value: (item) => item.skuName,
                },
                {
                    title: 'Магазин',
                    slot: 'shop',
                    value: (item) => item.retailerName,
                },
                {
                    title: 'Артикул',
                    slot: 'externalId',
                    width: 120,
                    value: (item) => item.externalId,
                },
                {
                    title: 'Наличие',
                    slot: 'availability',
                    value: (item) => item.skuAvailability,
                },
                {
                    title: 'Позиц. в выдаче',
                    value: (item) => item.avgRank,
                    alignRight: true,
                },
                {
                    title: 'Цена до скид.',
                    value: (item) => item.avgPriceBeforeDiscount,
                    alignRight: true,
                },
                {
                    title: 'Цена посл. скид.',
                    value: (item) => item.avgPriceAfterDiscount,
                    alignRight: true,
                },
                {
                    title: 'Сред. скидка',
                    value: (item) => item.avgDiscount,
                    alignRight: true,
                },
                {
                    title: 'Макс. скидка',
                    value: (item) => item.maxDiscount,
                    alignRight: true,
                },
                {
                    title: 'Рейтинг',
                    value: (item) => item.rating,
                    alignRight: true,
                },
                {
                    title: 'Отзывы',
                    value: (item) => item.commentCount,
                    alignRight: true,
                },
                {
                    title: 'Цена за гр.',
                    value: (item) => item.avgPricePerUnitOfWeight,
                    alignRight: true,
                },
            ]
        },

        tableOptions() {
            return {
                columns: this.tableColumns,
                data: this.items,
            }
        },
    },

    methods: {
        ...mapMutations('brandAndSku', [
          'setBrandId',
          'setRequestParams'
        ]),
        async searchItems() {
            this.currentPage = 1;
            await this.fetchData();
        },

        async fetchData() {
            this.isLoading = true;
            const brandId = this.$route.params.id;
            this.setBrandId(this.$route.params.id);
            this.setRequestParams(this.tableRequestParams);

            try {
                const response = await this.$api.common.getBrandsSKU(brandId, this.tableRequestParams);
                const brandName = response?.brandName || '';
                const totalCount = response?.count || 0;
                const isListValid = response && Array.isArray(response.list);

                this.$emit('updateBrand', brandName);
                this.totalCount = totalCount;
                this.items = isListValid ? response.list : [];
            } catch {
                this.totalCount = 0;
                this.items = [];
            }

            this.isLoading = false;
        },
    },

    watch: {
        async currentPage() {
            await this.fetchData();
        },

        async needUpdate() {
            this.currentPage = 1;
            await this.fetchData();
        }
    },

    async mounted() {
        await this.fetchData();
    }
}
</script>

<style scoped lang="scss">
.table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}

::v-deep .td__cell {
    word-break: normal;
}

.table-image{
  width: 40px;
  height: 40px;
  background-size: contain;
  filter: inset(1);
  background-repeat: no-repeat;
  background-position: center center;
}

.name {
    word-break: normal;
}

.shop {
  overflow: hidden;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.code {
    text-wrap: nowrap;
}

.icon {
    width: 100%;
    height: 100%;
}

.done {
    width: 13px;
    height: 10px;
    
    path {
        fill: #898989;
    }
}

.close {
    width: 11px;
    height: 11px;
    
    path {
        fill: #FC5C65;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerForData',{attrs:{"width":"100%","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('Search',{on:{"change":_vm.searchItems},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)]},proxy:true},{key:"data",fn:function(){return [_c('div',{staticClass:"table-content"},[(_vm.totalCount)?_c('Table',_vm._b({staticClass:"mb-12",scopedSlots:_vm._u([{key:"image",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-image",style:({ backgroundImage: ("url(" + value + ")") })})]}},{key:"name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.skuUrl)?_c('a',{attrs:{"href":item.skuUrl,"target":"_blank"}},[_vm._v(_vm._s(value))]):_c('div',{staticClass:"name"},[_vm._v(_vm._s(value))])]}},{key:"externalId",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"code"},[_vm._v(_vm._s(value))])]}},{key:"availability",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex items-center justify-center icon"},[(value)?_c('DoneIcon',{staticClass:"done"}):_c('CloseIcon',{staticClass:"close"})],1)]}},{key:"shop",fn:function(ref){
var value = ref.value;
return [_c('Tooltip',{scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"shop"},[_vm._v(_vm._s(value))])]},proxy:true}],null,true)},[_c('div',{staticClass:"shop-text"},[_vm._v(_vm._s(value))])])]}}],null,false,2223028023)},'Table',_vm.tableOptions,false)):_c('span',[_vm._v("No data.")]),_c('Pagination',{attrs:{"total":_vm.totalCount,"current":_vm.currentPage,"limit":_vm.limit},on:{"change-page":function($event){_vm.currentPage = $event}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
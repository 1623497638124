<template lang="pug">
ContainerForData(width="100%" :is-loading="isLoading")
    template(#header-right)
        .flex.items-center
            .flex.items-center
                button.date-button(
                    :disabled="datesFrom <= 0"
                    @click="changeShowedDates(-1)"
                ) &#x2039; Earler dates

                button.date-button(
                    :disabled="datesTo >= tableDates.length"
                    @click="changeShowedDates(1)"
                ) Later dates &#x203A;

            Search(v-model="searchValue" @change="searchItems")

    template(#data)
        .table-content
            Table.mb-12(v-if="totalCount" v-bind="tableOptions")
                template(#image="{ value }")
                    .table-image(:style="{ backgroundImage: `url(${value})` }")
                template(#name="{ item, value }")
                    a(
                        v-if="item.skuUrl"
                        :href="item.skuUrl"
                        target="_blank"
                    ) {{ value }}
                    .name(v-else) {{ value }}
                template(v-for="date in tableDates" #[date.slot]="{ value }")
                    .flex.items-center.justify-center.icon(:key="date.slot")
                        DoneIcon.done(v-if="value")
                        CloseIcon.close(v-else)
            
            span(v-else) No data.

            Pagination(
                :total="totalCount"
                :current="currentPage"
                :limit="limit"
                @change-page="currentPage = $event"
            ) 
</template>

<script>
import { mapMutations } from 'vuex'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Search from '@/components/Nestle/Search.vue';
import Table from '@/components/Table/Table.vue';
import DoneIcon from '@/assets/svg/done.svg';
import CloseIcon from '@/assets/svg/close.svg';
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
    components: {
        ContainerForData,
        Search,
        Table,
        DoneIcon,
        CloseIcon,
        Pagination,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            items: [],
            searchValue: '',

            limit: 20,
            totalCount: 0,
            currentPage: 1,

            maxShowedDates: 14,
            datesFrom: 0,
            datesTo: 4,

            sortBy: "skuName",
            sortOrder: "ASC",
        }
    },

    computed: {
        tableRequestParams() {
            return {
                ...this.requestParams,
                limit: this.limit,
                offset: this.limit * (this.currentPage - 1),
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                searchQuery: this.searchValue,
            }
        },

        tableDates() {
            const item = this.items[0];
            if (!item) return [];

            return item.availability.map((period) => {
                return {
                    title: this.$utils.format(this.$utils.parseISO(period.observedAt), 'MMM dd'),
                    width: 40,
                    slot: period.observedAt,
                    value: (item) => this.findAvailabilityValue(period.observedAt, item),
                    alignRight: true,
                }
            })
        },

        tableColumns() {
            return [
                {
                    title: 'Изобр.',
                    slot: 'image',
                    width: 40,
                    value: (item) => item.imageUrl,
                },
                {
                    title: 'Название SKU',
                    width: 200,
                    slot: 'name',
                    value: (item) => item.skuName,
                },
                ...this.tableDates.slice(this.datesFrom, this.datesTo),
            ]
        },

        tableOptions() {
            return {
                columns: this.tableColumns,
                data: this.items,
            }
        },
    },

    methods: {
        ...mapMutations('brandAndSku', [
          'setBrandId',
          'setRequestParams'
        ]),
        async searchItems() {
            this.currentPage = 1;
            await this.fetchData();
        },

        async fetchData() {
            this.isLoading = true;
            const brandId = this.$route.params.id;
            this.setBrandId(this.$route.params.id);
            this.setRequestParams(this.tableRequestParams);

            try {
                const response = await this.$api.common.getSKUAvailabilityPeriod(brandId, this.tableRequestParams);
                const brandName = response?.brandName || '';
                const totalCount = response?.count || 0;
                const isListValid = response && Array.isArray(response.list);

                this.$emit('updateBrand', brandName);
                this.totalCount = totalCount;
                this.items = isListValid ? response.list : [];
            } catch {
                this.totalCount = 0;
                this.items = [];
            }

            this.setDatesPaginationParams();
            this.isLoading = false;
        },

        setDatesPaginationParams() {
            const item = this.items[0];
            if (!item) return;

            const datesCount = item.availability?.length || 1;
            this.datesTo = datesCount;

            const datesFrom = this.datesTo - this.maxShowedDates;
            this.datesFrom = datesFrom <= 0 ? 0 : datesFrom;
        },

        changeShowedDates(direction) {
            this.datesFrom += direction;
            this.datesTo += direction;
        },

        findAvailabilityValue(date, item) {
            const currentPeriod = item.availability.find((period) => period.observedAt === date);
            return Boolean(currentPeriod?.skuAvailability);
        },
    },

    watch: {
        async currentPage() {
            await this.fetchData();
        },

        async needUpdate() {
            this.currentPage = 1;
            await this.fetchData();
        }
    },

    async mounted() {
        await this.fetchData();
    },
}
</script>

<style scoped lang="scss">
.table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}

::v-deep .td__cell {
    word-break: normal;
}

.date-button{
  font-size: 14px;
  background-color: #fff;
  outline: 1px color(gray-400) solid;
  padding: 0 5px;
  border-radius: 3px;
  margin-top: 1px;
  color: #303030;
  &:disabled {
    cursor: not-allowed;
    color: color(gray-600);
  }
}

.table-image{
  width: 40px;
  height: 40px;
  background-size: contain;
  filter: inset(1);
  background-repeat: no-repeat;
  background-position: center center;
}

.name {
    word-break: normal;
}

.icon {
    width: 100%;
    height: 100%;
}

.done {
    width: 13px;
    height: 10px;
    
    path {
        fill: #898989;
    }
}

.close {
    width: 11px;
    height: 11px;
    
    path {
        fill: #FC5C65;
    }
}
</style>
